import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  FadeReveal,
  Image,
  MarkdownContent,
  InternalLink,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Section className={"header-section"}>
            <Grid stackable={true}>
              <Grid.Column width={8} className={"image-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <Image
                    src={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "Content.Image",
                      componentIdentifier: "content_section_image",
                      numToSelect: 1,
                    })}
                    background={true}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8} className={"content-column"}>
                <div>
                  <FadeReveal
                    duration={1250}
                    triggerOnce={true}
                    delay={450}
                    cascade={true}
                  >
                    <Header
                      as={"h1"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_header",
                        defaultValue: "About",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_description",
                      })}
                    />
                    <div className={"ctas-container"}>
                      <Button
                        secondary={true}
                        to={"/services/"}
                        as={InternalLink}
                        event={{
                          category: "Booking",
                          action: "Schedule Appointment Intent",
                        }}
                      >
                        View Services
                      </Button>
                    </div>
                  </FadeReveal>
                </div>
              </Grid.Column>
            </Grid>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
